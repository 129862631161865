import React from "react";
import DefaultImg from "../../../../assets/img/default-img.png";
import {useSelector} from "react-redux";
import {TranslatedAttribute} from "../../../../utils";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SocialMediaIcons from "../../components/SocialMediaIcons";
import Header from "../../components/Header/Header";
import {motion as m} from "framer-motion";

const Home = () => {
  const {menuInfo, locale, isShowFeedbackForm, isGoogleComment, googleCommentUrl} = useSelector(state => state.app)
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <m.div
      key={'menu'}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 0.3, ease: "easeOut"}}
      exit={{opacity: 0}}
      className="h-full">
      <div className={'h-full w-full relative'}>
        <div className={'absolute z-20 right-0 mt-2 mr-4'}>
          <Header/>
        </div>
        <div className={'h-full flex flex-col absolute justify-center items-center text-white w-full z-10'}>
          <img src={menuInfo?.logo || DefaultImg} alt="Kardo QR Menü"
               width={125}
               height={125}/>
          <div className={'text-center'}>
            {
              TranslatedAttribute(locale, menuInfo, 'title') ?
                <h1
                  className={'text-white text-[18pt] text-center mt-3 mb-0 font-semibold'}>{TranslatedAttribute(locale, menuInfo, 'title') || ''}</h1> : <></>
            }
            <h3
              className={'text-white text-[15px] text-center px-5 italic'}>{TranslatedAttribute(locale, menuInfo, 'sub_title') || ''}</h3>
          </div>
          <div className={'mt-[25px] w-full'}>
            <div className={'border bg-white text-primary text-center mx-[70px] rounded-[5px]'}
                 onClick={() => navigate('/menu')}>
              {t('menu.go_menu')}
            </div>
            {
              isShowFeedbackForm && (
                <div className={'border bg-white text-primary text-center mx-[70px] mt-3 rounded-[5px]'}
                     onClick={() => navigate('/feedback')}>
                  {t('menu.feedback_form')}
                </div>
              )
            }
            {
              isGoogleComment && (
                <a className={'border bg-white text-primary text-center mx-[70px] mt-3 rounded-[5px] block'}
                   href={googleCommentUrl}>
                  {t('menu.google_rating')}
                </a>
              )
            }

          </div>
          <SocialMediaIcons social_medias={menuInfo.social_media}/>
        </div>
        {
          menuInfo?.cover && (
            <>
              <div className={'w-full h-full bg-black/[0.35] absolute z-0'}/>
              <img src={menuInfo?.cover} alt="" className={'h-full w-full object-cover'}/>
            </>
          )
        }
      </div>
    </m.div>
  )
}

export default Home;