import {destroyModal} from "../../utils/modal";
import {Drawer} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import React, {useCallback, useState} from "react";
import {useAddNewOrderV2Mutation} from "../../../../storage/api";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import Loader from "../Loader";


import {deleteAllItem, deleteItem, noteScreenSaveAndCloseV2, setOrderV2, TranslatedAttribute} from "../../../../utils/";


const BasketModal = () => {
  const {basket, currencySymbol, tableId, isRequiredNoteScreen, menuInfo, locale} = useSelector(state => state.app);
  const [addNewOrder,] = useAddNewOrderV2Mutation()
  const [name, setName] = useState(null);
  const [note, setNote] = useState(null);
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation();
  const [dataLoading, setDataLoading] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);

  const noteScreenOpen = () => {
    setOpen(true);
  };

  const noteScreenClose = () => {
    setOpen(false);
  };

  const setLoad = useCallback((res) => {
    setDataLoading(res)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoading]);

  const setModalOpen = useCallback((res) => {
    setOpen(res)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const data = {
    'addNewOrder': addNewOrder,
    'menuInfo': menuInfo,
    'tableId': tableId,
    'basket': basket,
    'name': name,
    'note': note,
    'setLoad': setLoad,
    'setModalOpen': setModalOpen,
    'setSaveButtonDisable': setSaveButtonDisable
  }

  return (
    <div style={{position: 'relative', zIndex: 0}}>
      {
        dataLoading ? (
          <Loader/>
        ) : (
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor: "transparent",
                boxShadow: 'none',
                zIndex: 120
              }
            }}
            anchor={'bottom'}
            open={true}
            onClose={destroyModal}
          >

            <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-1/2 rounded-t-[30px] p-4 relative'}>
              <FontAwesomeIcon icon={faCircleXmark} size={'2x'}
                               className={'image-text-secondary w-[40px] h-[40px] absolute'} style={{right: 5, top: 5}}
                               onClick={destroyModal}/>

              <div
                className={'overflow-scroll no-scrollbar h-[400px] flex flex-col justify-start items-center pb-6 mt-10'}>
                <div className={'w-full text-center flex flex-col'}>
                  <span className={'text-[#555] text-[22px] font-bold'}> {t('general.my_basket')} </span>
                  <span>{t('general.basket_text')}</span>
                </div>

                <div className={'w-full mt-5'}>

                  {
                    basket && basket.items.length > 0 ? (
                      basket.items.map((item, index) => {
                        return (
                          <div key={`basket-item-${item.identifier}`}
                               className={'w-full flex flex-row items-center justify-between mb-5 grid grid-cols-12 gap-4 border-b-2 border-gray-200 pb-4'}>
                            <div
                              className={'w-[45px] h-[45px] bg-[#f8f8f8] flex justify-center items-center rounded-full col-span-2'}>
                              <span className={'font-bold text-[18px] text-[#555]'}>{item.amount}</span>
                            </div>
                            <div className={'col-span-8 flex flex-col'}>
                              <span className={'font-bold'}>{TranslatedAttribute(locale, item, 'name')}</span>
                              <p
                                className={'text-[12px] font-normal'}>{TranslatedAttribute(locale, item, 'description')}</p>

                              {
                                (item.variants).map(variant => {
                                  return <div key={`${item.identifier}-${variant.id}`}
                                              className={'text-[#a1a1a1] mb-1'}> {TranslatedAttribute(locale, variant, 'name')} (+{variant.additional_price} {currencySymbol})</div>
                                })
                              }

                              {
                                (item.options).map(option => {
                                  return <div key={option.id}
                                              className={'text-[#a1a1a1] mb-1'}>
                                    {option.name} (+{option.price} {currencySymbol}) {(option.amount > 1) ? `(${option.amount}x)` : ''}
                                  </div>
                                })
                              }

                              <span
                                className={'text-sm font-bold'}>{(`${(item?.total_price).toFixed(2) + ' ' + currencySymbol}`)}</span>
                            </div>
                            <div onClick={() => deleteItem(item.identifier, basket)}
                                 className={'w-[45px] h-[45px] bg-[#8d3d3d] flex justify-center items-center rounded-full col-span-2'}>
                              <FontAwesomeIcon icon={faTrashCan} color={'#fff'}/>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div className={'my-2 bg-[#d0e9e7] text-[#555] text-center w-full p-2 rounded-xl'}>
                        {t('general.no_product')}
                      </div>
                    )

                  }


                  {
                    basket.items.length > 0 ? (
                      <>
                        <div className={'w-full flex justify-center mt-8'}>
                          <button onClick={() => isRequiredNoteScreen ? noteScreenOpen() : setOrderV2(data)}
                                  className={'w-full rounded-3xl text-[#fff] px-5 py-3 image-bg-primary font-bold'}>
                            {t('general.order')}
                          </button>
                        </div>

                        <div className={'w-full flex justify-center mt-8'}>
                          <button onClick={() => deleteAllItem()}
                                  className={'w-full rounded-3xl image-text-primary px-5 py-3'}>
                            <span className={'mr-4 font-bold'}>{t('general.clear_basket')}</span>
                            <FontAwesomeIcon icon={faTrashCan}/>
                          </button>
                        </div>

                      </>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </Drawer>
        )

      }

      <Dialog
        open={open}
        onClose={noteScreenClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {""}
        </DialogTitle>
        <DialogContent>
          <div>
            <div>
              <label htmlFor="first_name"
                     className="block mb-2 text-sm font-medium text-gray-900">{t('general.name')}: </label>
              <input type="text" id="first_name"
                     className="image-bg-thirdy border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                     placeholder={t('general.name')} autoComplete={'off'} onChange={(e) => setName(e.target.value)}/>
            </div>

            <div>
              <label htmlFor="message"
                     className="block mb-2 text-sm font-medium text-gray-900">{t('general.note')}: </label>
              <textarea id="message" rows="4"
                        className="resize-none block p-2.5 w-full text-sm text-gray-900 image-bg-thirdy rounded-lg border border-gray-300"
                        placeholder={t('general.note')} autoComplete={'off'}
                        onChange={(e) => setNote(e.target.value)}></textarea>
            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <div className={'mx-3'}>
            <button className={'image-bg-primary text-white px-5 py-2 rounded-xl m-1'}
                    onClick={noteScreenClose}>{t('general.cancel')}</button>
            <button
              className={`${saveButtonDisable ? 'bg-[#ddd]' : 'image-bg-primary'} text-white px-3 py-2 rounded-xl m-1`}
              disabled={saveButtonDisable} onClick={() => noteScreenSaveAndCloseV2(data)}
              autoFocus>{t('general.save')}</button>
          </div>
        </DialogActions>
      </Dialog>

    </div>
  )
}


export default BasketModal;