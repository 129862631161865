import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import DefaultImg from "../../../../assets/img/default-img.png";
import {useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {useGetProductQuery} from "../../../../storage/api";
import {useTranslation} from "react-i18next";
import {addToCartV2, TranslatedAttribute} from "../../../../utils";
import Loader from "../../components/Loader";
import Counter from "../../../../components/form/Counter";
import ProductForm from "./ProductForm";
import toast from "react-hot-toast";
import BasketButton from "../../components/BasketButton";
import useForm from "../../../../components/form";

const ProductDetail = () => {
  const {currencySymbol, locale, isOrderAvailable, basket} = useSelector(state => state.app);
  const navigate = useNavigate();
  const {state} = useLocation();
  const {item} = state;
  const [totalPrice, setTotalPrice] = useState(0);
  const {data} = useGetProductQuery({id: item.id});
  const {t} = useTranslation();
  const {loading} = useSelector(state => state.loader)
  const {formData, handleInputChange, setFormData} = useForm()
  const [variantsObject, setVariantsObject] = useState()

  const SubmitForm = ({price}) => {
    return (
      <div className={'flex justify-center flex-col sticky bottom-0 left-0 right-0 bg-[#fff] pb-16'}>
        <div className={'w-full text-right flex justify-end items-end mt-[15px]'}>
          <div className={'text-[21px]'}>
                      <span className={'font-bold text-[#555555]'}>
                        {t('general.total')}
                      </span>
            {` ${price?.toFixed(2) + ' ' + currencySymbol}`}
          </div>
        </div>
        <div className={'flex justify-center'}>
          <button
            onClick={() => formData?.amount > 0 ? addToCartHandle() : toast.error(t('general.determine_the_product_quantity'))}
            className={'w-1/2 rounded-3xl text-[#fff] px-5 py-3 font-bold mt-2 ' + (formData?.amount === 0 ? 'bg-[#e4e4e4]' : 'bg-[#000]')}>
            {t('general.add_to_cart')} ({basket.items.length})
          </button>
        </div>
      </div>
    )
  }

  const minValidation = () => {
    let flag = true
    Object.entries(variantsObject).forEach(([key, value]) => {
      if (value.min > 0 && (!formData.variants[key] || Object.values(formData.variants[key]).length < value.min)) {
        flag = false
        toast.error(t('general.minimum_number_of_selections', {
          item: TranslatedAttribute(locale, variantsObject[key], 'name'),
          quantity: variantsObject[key].min
        }));
      }
    })
    return flag
  }

  // Variantlar min: 0, max: 1 ise doldurulmak zorunda degiller bu yuzden yorum satiri ilerde kullanilabilir.
  const checkRequiredFields = () => {
    // return Object.entries(formData.variants).every(([_, value]) => {
    //   return Object.keys(value).length !== 0;
    // });
    return true
  }

  const addToCartHandle = () => {
    if (minValidation() && checkRequiredFields()) {
      addToCartV2(formData)
      navigate(-1)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calc_total_price = () => {
    let sum_price = 0
    Object.entries(Object.values(formData.variants)).forEach(([_, values]) => {
      Object.values(values).forEach((value) => {
        sum_price += parseFloat(value.additional_price);
      })
    })
    Object.entries(Object.values(formData.options)).forEach(([_, value]) => {
      sum_price += (parseFloat(value.price) || 0) * (value.amount || 1);
    })
    const price = (((data.price || 0) + sum_price) * formData.amount)
    formData['total_price'] = price;
    setTotalPrice(price);
  }

  const setFormDataOnPageLoad = useCallback(() => {
    setFormData({
      "identifier": '0',//new Date().getTime(),
      "id": data.id,
      "name": TranslatedAttribute(locale, data, 'name'),
      "price": data.price[currencySymbol],
      "amount": 1,
      "total_price": item.price[currencySymbol],
      "unit_price": 0,
      "variants": {},
      "options": {}
    })
  }, [currencySymbol, data, item.price, locale, setFormData])

  useEffect(() => {
    if (data) {
      let variantObj = {}
      data.variants.forEach((variant) => {
        variantObj[variant.id] = variant
      })
      setVariantsObject(variantObj)
    }
  }, [data])

  useEffect(() => {
    data && setFormDataOnPageLoad()
  }, [currencySymbol, data, item.price, locale, setFormDataOnPageLoad])

  useEffect(() => {
    formData && calc_total_price()
  }, [calc_total_price, formData]);

  if (formData)
    return (
      <>
        <div className={'bg-[#000] py-4 px-2 text-[#fff] pb-[75px] flex justify-start items-center'}>
          <div onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} color={'#fff'} className={'font-bold mx-3 w-[20px] h-[20px]'}/>
          </div>
          <span>{TranslatedAttribute(locale, item, 'name')}</span>
        </div>

        <div className={'w-full h-1/3 flex justify-center items-center overflow-hidden'}>
          <img src={item.full_size_url || DefaultImg} alt="" className={'w-full object-cover'}/>
        </div>

        <div className={'w-full p-2'}>

          <div className={'w-full'}>
            <div>
              <div className={'font-bold text-[18px]'}>{TranslatedAttribute(locale, item, 'name')}</div>
              <div className={'font-normal text-[12px] mb-2'}>{TranslatedAttribute(locale, item, 'description')}</div>
            </div>

            <div className={'flex flex-row items-center justify-between'}>
              {
                item.resource_type === 'ProductCategory' ? (
                  <></>
                ) : (
                  <span
                    className={'text-[18px]'}>
                    {(item.price[currencySymbol].toString().includes('-') ?
                      item.price[currencySymbol] :
                      `${parseFloat(item.price[currencySymbol]).toFixed(2)} ${currencySymbol}`)}
                  </span>
                )
              }
              {
                !item.sub_items && isOrderAvailable &&
                <Counter name={'amount'} formData={formData} onUpdate={calc_total_price} iconClassNames={'text-[#000]'}
                         iconDisabledClassNames={'text-[#e4e4e4]'}/>
              }
            </div>
          </div>
          {
            item?.sub_items ? (
              <div className={'bg-[#f3f4f6] p-2 mt-3'}>
                <p className={'bg-[#f3f4f6] text-[15px]'}>{t('general.select')}</p>
                {
                  item.sub_items.map(subItem => {
                    return (
                      <div key={subItem.id} onClick={() => navigate('/product-detail', {state: {item: subItem}})}
                           className={'bg-[#fff] flex flex-row justify-between items-center m-3'}>
                        <div className={'ml-3'}>
                          <p className={'font-semibold'}>{subItem.name}</p>
                          <p>{(`${(subItem?.price[currencySymbol]).toFixed(2) + ' ' + currencySymbol}`)}</p>
                        </div>
                        <div className={'h-[65px] flex justify-center items-center overflow-hidden'}>
                          <img src={subItem.image_url} alt="" className={'w-[120px] object-contain'}/>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            ) : isOrderAvailable && (
              <>
                <ProductForm formData={formData} data={data} calc_total_price={calc_total_price}
                             handleInputChange={handleInputChange}/>
                <SubmitForm price={totalPrice}/>
              </>
            )
          }
        </div>
        {
          isOrderAvailable && (item.resource_type !== '') ? <BasketButton/> : <></>
        }
        {
          loading ? <Loader/> : null
        }
      </>
    );
}

export default ProductDetail;